import React from 'react'
import Layout from '../components/layout'
import PageTransition from 'gatsby-plugin-page-transitions'
import PageHeading from '../UI/PageHeading/PageHeading'
import { Row } from '../UI/Layouts/Layouts'
import { Text, Title } from './../UI/Typing'
import Blank from '../UI/Blank/Blank'
import rectBlank from '../Assets/rectBlank.png'
import rectHBlank from '../Assets/rect.png'
import Animotion from '../UI/Animotion'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { Parallax } from 'react-scroll-parallax'
import { RoomButton } from '../UI/Button/Button'
import './rooms.styl'

// import images
import HeadImg from './../Assets/images_pg/rooms/headroom.jpg'
import Room1m from './../Assets/images_pg/rooms/talia1.jpg'
import Room1s from './../Assets/images_pg/rooms/talia2.jpg'
import Room2m from './../Assets/images_pg/rooms/artemide1.jpg'
import Room2s from './../Assets/images_pg/rooms/artemide2.jpg'
import Room3m from './../Assets/images_pg/rooms/dafne1.jpg'
import Room3s from './../Assets/images_pg/rooms/dafne2.jpg'
import Room4m from './../Assets/images_pg/rooms/dioniso1.jpg'
import Room4s from './../Assets/images_pg/rooms/dioniso2.jpg'
import Room5m from './../Assets/images_pg/rooms/027.jpg'
import Room5s from './../Assets/images_pg/rooms/029.jpg'

const Line = () => <span className='Line' />

const RoomRow = props => (
  <div className={`RoomRow ${props.position}`}>
    <Row>
      <div className='RoomImg'>
        <Blank sizer={rectBlank} image={props.image} />
      </div>
      <div className='RoomDesc'>
        <Blank sizer={rectBlank} image={props.subimage} />
        <RoomInfo {...props} />
      </div>
    </Row>
  </div>
)

const RoomColumn = props => (
  <div className={`RoomColumn ${props.position}`}>
    <div>
      <div style={{ zIndex: 3, position: 'relative' }}>
        <Parallax offsetYMax={20} offsetYMin={-20}>
          <Blank sizer={rectHBlank} image={props.image} />
        </Parallax>
      </div>
      <div>
        <div>
          <Parallax slowerScrollRate offsetYMax={13} offsetYMin={-13}>
            <Blank sizer={rectBlank} image={props.subimage} />
          </Parallax>
        </div>
        <RoomInfo {...props} />
      </div>
    </div>
  </div>
)

const bookingLink =
  'https://www.booking.com/hotel/it/klanjscek-wine-amp-stay.it.html?aid=898224&app_hotel_id=4656073&checkin=2019-03-29&checkout=2019-03-30&from_sn=ios&group_adults=2&group_children=0&label=Share-7tffO1%401552551640&no_rooms=1&req_adults=2&req_children=0&room1=A%2CA%2C'

const RoomInfo = props => (
  <aside>
    <Animotion>
      <Title>{props.title}</Title>
      <Line />
      <Text>{props.description}</Text>
      <RoomButton to={bookingLink}>
        <FormattedMessage id='bookRoom' />
      </RoomButton>
    </Animotion>
  </aside>
)

const RoomPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <PageTransition>
      <PageHeading
        title={<FormattedMessage id='rooms' />}
        subtitle={<FormattedMessage id='roomsSubtitle' />}
        text={<FormattedMessage id='roomsDescription' />}
        image={HeadImg}
      />
      <section className='Rooms'>
        <Animotion>
          <RoomRow
            title={<FormattedMessage id='room1Title' />}
            description={<FormattedMessage id='room1Description' />}
            position='left'
            image={Room3m}
            subimage={Room3s}
          />
        </Animotion>

        <Animotion>
          <RoomColumn
            position='left'
            title={<FormattedMessage id='room2Title' />}
            description={<FormattedMessage id='room2Description' />}
            image={Room2m}
            subimage={Room2s}
          />
        </Animotion>
        <Animotion>
          <RoomColumn
            position='right'
            title={<FormattedMessage id='room3Title' />}
            description={<FormattedMessage id='room3Description' />}
            image={Room1m}
            subimage={Room1s}
          />
        </Animotion>
        <Animotion>
          <RoomRow
            position='right'
            title={<FormattedMessage id='room4Title' />}
            description={<FormattedMessage id='room4Description' />}
            image={Room4s}
            subimage={Room4m}
          />
        </Animotion>
        <Animotion>
          <RoomColumn
            position='right'
            title={<FormattedMessage id='room5Title' />}
            description={<FormattedMessage id='room5Description' />}
            image={Room5m}
            subimage={Room5s}
          />
        </Animotion>
      </section>
    </PageTransition>
  </Layout>
)

export default RoomPage
